import IApiService from "./ApiService";
import TokenSet from "./../types/TokenSet";
import Store from "@/store";
import LocalStorageService from "./LocalStorageService";
import StorageKey from "@/types/StorageKey";
import ApiService from "./ApiService";
import Self from "@/types/Self";

class UserService {
  private readonly _apiService: IApiService;
  private readonly _localStorageService: LocalStorageService;

  constructor(
    apiService: IApiService,
    localStorageService: LocalStorageService
  ) {
    this._apiService = apiService;
    this._localStorageService = localStorageService;
  }

  public async passwordLogin(
    username: string,
    password: string
  ): Promise<TokenSet> {
    const response: Response = await this._apiService.request(
      "POST",
      `/platform/users/password-login`,
      undefined,
      false,
      {
        body: {
          username,
          password,
        },
      },
      false
    );
    const tokenset = response.body as unknown as TokenSet;

    this._localStorageService.set(
      StorageKey.TOKENSET,
      JSON.stringify(tokenset)
    );

    Store.dispatch("setLoggedIn", true);

    return tokenset;
  }

  public async fetchSelf(): Promise<Self> {
    const response: Response = await this._apiService.request(
      "GET",
      `/platform/users/self`,
      undefined,
      false,
      {}
    );
    const self = response.body as unknown as Self;
    this._localStorageService.set(StorageKey.ACCOUNT, JSON.stringify(self.sub));
    return self;
  }
}

export default new UserService(
  new ApiService(new LocalStorageService()),
  new LocalStorageService()
);
