enum ProductInternalStatus {
  PRODUCT_CREATED = "product_created",
  WAITING_FOR_PRODUCT_DESCRIPTION = "waiting_for_product_description",
  PRODUCT_DESCRIPTION_CONFIDENCE_TOO_LOW = "product_description_confidence_too_low",
  PRODUCT_DESCRIPTION_EXTRACTION_FAILED = "product_description_extraction_failed",
  PRODUCT_DESCRIPTION_EXTRACTED = "product_description_extracted",
  WAITING_FOR_EMISSIONS = "waiting_for_emissions",
  PRODUCT_EMISSIONS_REQUEST_FAILED = "product_emissions_request_failed",
  PRODUCT_EMISSIONS_CONFIDENCE_TOO_LOW = "product_emissions_confidence_too_low",
  DONE = "done",
}

enum ProductExternalStatus {
  DONE = "done",
  PENDING = "pending",
}

enum ScriptStatus {
  COMPLETE = "Complete",
  FAILED = "Failed",
  LOW_CONFIDENCE = "Low confidence",
}

const internalStatusMapper = (
  productStatus: ProductInternalStatus
): {
  text: string;
  color: string;
} => {
  switch (productStatus) {
    case ProductInternalStatus.PRODUCT_CREATED:
      return {
        text: "Created",
        color: "#1890FF",
      };
    case ProductInternalStatus.WAITING_FOR_PRODUCT_DESCRIPTION:
      return {
        text: "Waiting for PD",
        color: "#1890FF",
      };
    case ProductInternalStatus.PRODUCT_DESCRIPTION_CONFIDENCE_TOO_LOW:
      return {
        text: "Low confidence",
        color: "#FF4D4F",
      };
    case ProductInternalStatus.PRODUCT_DESCRIPTION_EXTRACTION_FAILED:
      return {
        text: "Failed extraction",
        color: "#FF4D4F",
      };
    case ProductInternalStatus.PRODUCT_DESCRIPTION_EXTRACTED:
      return {
        text: "PD extracted",
        color: "#1890FF",
      };
    case ProductInternalStatus.WAITING_FOR_EMISSIONS:
      return {
        text: "Waiting for emissions",
        color: "#1890FF",
      };
    case ProductInternalStatus.PRODUCT_EMISSIONS_REQUEST_FAILED:
      return {
        text: "Failed extraction",
        color: "#FF4D4F",
      };
    case ProductInternalStatus.PRODUCT_EMISSIONS_CONFIDENCE_TOO_LOW:
      return {
        text: "Low confidence",
        color: "#FF4D4F",
      };
    case ProductInternalStatus.DONE:
      return {
        text: "Done",
        color: "#52C41A",
      };
    default: {
      return {
        text: "",
        color: "",
      };
    }
  }
};

const externalStatusMapper = (
  productStatus: ProductExternalStatus
): {
  text: string;
  color: string;
} => {
  switch (productStatus) {
    case ProductExternalStatus.DONE:
      return {
        text: "Done",
        color: "#52C41A",
      };
    case ProductExternalStatus.PENDING:
      return {
        text: "Pending",
        color: "#FF4D4F",
      };
    default: {
      return {
        text: "",
        color: "",
      };
    }
  }
};

const CGEngineStatusMapper = (
  productStatus: ProductInternalStatus
): {
  text: string;
  color: string;
} => {
  switch (productStatus) {
    case ProductInternalStatus.DONE:
      return {
        text: ScriptStatus.COMPLETE,
        color: "#52C41A",
      };
    case ProductInternalStatus.PRODUCT_EMISSIONS_CONFIDENCE_TOO_LOW:
      return {
        text: ScriptStatus.LOW_CONFIDENCE,
        color: "#FF4D4F",
      };
    case ProductInternalStatus.PRODUCT_EMISSIONS_REQUEST_FAILED:
      return {
        text: ScriptStatus.FAILED,
        color: "#FF4D4F",
      };
    default: {
      return {
        text: "",
        color: "",
      };
    }
  }
};

const productDetailsMapper = (
  productStatus: ProductInternalStatus
): {
  text: string;
  color: string;
} => {
  switch (productStatus) {
    case ProductInternalStatus.DONE:
      return {
        text: ScriptStatus.COMPLETE,
        color: "#52C41A",
      };
    case ProductInternalStatus.PRODUCT_DESCRIPTION_CONFIDENCE_TOO_LOW:
      return {
        text: ScriptStatus.LOW_CONFIDENCE,
        color: "#FF4D4F",
      };
    case ProductInternalStatus.PRODUCT_DESCRIPTION_EXTRACTION_FAILED:
      return {
        text: ScriptStatus.FAILED,
        color: "#FF4D4F",
      };
    default: {
      return {
        text: "",
        color: "",
      };
    }
  }
};

export {
  internalStatusMapper,
  externalStatusMapper,
  CGEngineStatusMapper,
  productDetailsMapper,
  ProductExternalStatus,
  ProductInternalStatus,
  ScriptStatus,
};
