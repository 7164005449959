var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-page"},[(_vm.product)?_c('div',{staticClass:"product-page-content"},[(_vm.product.ExtractedProduct)?_c('div',{staticClass:"product-page-title"},[_vm._v(" "+_vm._s(_vm.product.ID)+" - "+_vm._s(_vm.product.ExtractedProduct.Title)+" ")]):_vm._e(),_c('div',{staticClass:"product-page-content-first"},[_c('div',{staticClass:"product-page__original card"},[_c('span',{staticClass:"card-title"},[_vm._v("Original request")]),_c('a',{staticClass:"edit-button",on:{"click":_vm.onOriginalEdit}},[_vm._v("Edit")]),_c('div',[_vm._v("Title")]),_c('a-input',{attrs:{"disabled":_vm.disableOriginalEdit,"value":_vm.product.ExtractedProduct.Title},on:{"change":_vm.onTitleChange}}),_c('div',[_vm._v("Taxonomy")]),_c('a-input',{attrs:{"disabled":_vm.disableOriginalEdit,"value":_vm.taxonomy},on:{"change":_vm.onTaxonomyChange}}),_c('div',[_vm._v("Description")]),_vm._l((_vm.product.ExtractedProduct.ProductDescription),function(item,index){return _c('a-input',{key:index,attrs:{"disabled":_vm.disableOriginalEdit,"value":item.Html},on:{"change":function ($event) { return _vm.onDescriptionChange($event, index); }}})}),_c('a-button',{attrs:{"disabled":_vm.disableOriginalEdit},on:{"click":_vm.handleAddDescription}},[_vm._v("Add more description")])],2),_c('div',{staticClass:"product-page__status"},[_c('div',[_vm._v("Internal status")]),_c('a-dropdown-button',{staticStyle:{"margin-left":"8px"}},[_c('ul',[_c('li',{style:({
                color: _vm.internalStatusMapper(_vm.product.InternalStatus).color,
              })},[_vm._v(" "+_vm._s(_vm.internalStatusMapper(_vm.product.InternalStatus).text)+" ")])]),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.handleStatusSelect},slot:"overlay"},_vm._l((_vm.ProductInternalStatus),function(item){return _c('a-menu-item',{key:item},[_c('ul',[_c('li',{style:({ color: _vm.internalStatusMapper(item).color })},[_vm._v(" "+_vm._s(_vm.internalStatusMapper(item).text)+" ")])])])}),1)],1),_c('div',[_vm._v("External status")]),_c('ul',{staticClass:"external-status"},[_c('li',{style:({
              color: _vm.externalStatusMapper(_vm.product.PublicStatus).color,
            })},[_vm._v(" "+_vm._s(_vm.externalStatusMapper(_vm.product.PublicStatus).text)+" ")])]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onUpdateStatus}},[_vm._v("Update status")])],1)]),_c('div',{staticClass:"product-page-content-second card"},[_c('span',{staticClass:"card-title"},[_vm._v("Emissions")]),_c('a-table',{attrs:{"columns":_vm.emissionColumns,"data-source":_vm.emissions,"pagination":false}})],1),_c('div',{staticClass:"product-page-content-third"},[_c('div',{staticClass:"product-page__details card"},[_c('span',{staticClass:"card-title"},[_vm._v("Product Details")]),_c('ul',{staticClass:"script-status",style:({
            color: _vm.productDetailsStatus.color,
          })},[(_vm.productDetailsStatus.text)?_c('li',[_vm._v(" "+_vm._s(_vm.productDetailsStatus.text)+" ")]):_vm._e()]),_c('a-button',{staticClass:"float-button-right",attrs:{"type":"primary","loading":_vm.loadingExtraction},on:{"click":_vm.onReRunExtraction}},[_vm._v("Re-run pdextraction")]),_c('a-textarea',{key:_vm.autoSizeProductDetials.maxRows,attrs:{"disabled":true,"value":JSON.stringify(_vm.product, undefined, 4),"autoSize":_vm.autoSizeProductDetials}}),_c('a',{on:{"click":_vm.onExpandProductDetails}},[_vm._v("Expand")])],1),_c('div',{staticClass:"product-page__request card"},[_c('span',{staticClass:"card-title"},[_vm._v("CGEngine request")]),_c('ul',{staticClass:"script-status",style:({
            color: _vm.cgEngineStatus.color,
          })},[(_vm.cgEngineStatus.text)?_c('li',[_vm._v(" "+_vm._s(_vm.cgEngineStatus.text)+" ")]):_vm._e()]),_c('a',{staticClass:"edit-button-middle",on:{"click":_vm.onCGEngineRequestEdit}},[_vm._v("Edit CGengine query")]),_c('a-button',{staticClass:"float-button-right",attrs:{"type":"primary","loading":_vm.loadingCGengine},on:{"click":_vm.onReRunCGengine}},[_vm._v("Re-run cgengine query")]),_c('a-textarea',{key:_vm.autoSizeCGEngineRequest.maxRows,attrs:{"disabled":_vm.disableCGEngineRequestEdit,"value":JSON.stringify(_vm.product.CGEngineReq, undefined, 4),"auto-size":_vm.autoSizeCGEngineRequest}}),_c('a',{on:{"click":_vm.onExpandCGEngineRequest}},[_vm._v("Expand")])],1)])]):_c('div',[_vm._v("No product with this Id")])])}
var staticRenderFns = []

export { render, staticRenderFns }