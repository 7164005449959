import ProductDescriptionItem from "@/types/ProductDescriptionItem";
import ProductListResponst from "@/types/ProductListResponst";
import ProductResponse from "@/types/ProductResponse";
import ApiService from "./ApiService";
import LocalStorageService from "./LocalStorageService";

class ProductService {
  private readonly _apiService: ApiService;
  private readonly _localStorageService: LocalStorageService;

  constructor(
    apiService: ApiService,
    localStorageService: LocalStorageService
  ) {
    this._apiService = apiService;
    this._localStorageService = localStorageService;
  }

  public async getList(
    filter: string | undefined,
    page: number,
    pageSize: number,
    sort: string | undefined
  ): Promise<{ List: Array<ProductResponse>; TotalCount: number }> {
    const query = {
      page,
      page_size: pageSize,
    };
    // if (filter) (query as any).filter = filter;
    if (sort) (query as any).sort = sort;
    const response: Response = await this._apiService.request(
      "GET",
      `/products`,
      query,
      false,
      {},
      true,
      filter
    );
    return {
      List: (response.body as unknown as ProductListResponst).List,
      TotalCount: (response.body as unknown as ProductListResponst).TotalCount,
    };
  }

  public async updateDescription(
    product: string,
    Taxonomy?: Array<string>,
    Title?: string,
    ProductDescription?: ProductDescriptionItem[]
  ): Promise<void> {
    await this._apiService.request(
      "PUT",
      `/product/${product}/description`,
      undefined,
      false,
      {
        body: {
          Taxonomy,
          Title,
          ProductDescription,
        },
      }
    );
  }

  public async updateStatus(product: string, Status: string): Promise<void> {
    await this._apiService.request(
      "PUT",
      `/product/${product}/status`,
      undefined,
      false,
      {
        body: {
          Status,
        },
      }
    );
  }

  public async ReRunExtraction(product: string): Promise<void> {
    await this._apiService.request(
      "POST",
      `/product/${product}/re-run/extraction`,
      undefined,
      false,
      undefined
    );
  }

  public async ReRunEmissionsCalculation(product: string): Promise<void> {
    await this._apiService.request(
      "POST",
      `/product/${product}/re-run/emissions`,
      undefined,
      false,
      undefined
    );
  }
}

export default new ProductService(
  new ApiService(new LocalStorageService()),
  new LocalStorageService()
);
