












import { defineComponent, ref } from "@vue/composition-api";
import UserSerivce from "@/serives/UserService";

export default defineComponent({
  name: "Login",
  setup(props, context) {
    const router = context.root.$router;

    const username = ref<string>("");
    const secret = ref<string>("");

    const onUsernameInput = (event: { target: HTMLInputElement }): void => {
      username.value = event.target.value;
    };

    const onSecretInput = (event: { target: HTMLInputElement }): void => {
      secret.value = event.target.value;
    };

    const onSubmit = async (): Promise<void> => {
      await UserSerivce.passwordLogin(username.value, secret.value);
      router.push("/");
    };

    return {
      username,
      secret,
      onUsernameInput,
      onSecretInput,
      onSubmit,
    };
  },
});
