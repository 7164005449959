import StorageKey from "@/types/StorageKey";
import TokenSet from "../types/TokenSet";
import LocalStorageService from "./LocalStorageService";

class ApiService {
  private readonly baseUrl: string;
  private readonly _localStorageService: LocalStorageService;

  constructor(localStorageService: LocalStorageService) {
    this.baseUrl = "";
    this._localStorageService = localStorageService;
  }

  public async request(
    method: string,
    url: string,
    query: any = null,
    absoluteUrl = false,
    options: any = {},
    refresh = true,
    absoluteQuery: string | null = null
  ): Promise<any> {
    // const tokenSet = await getToken(options.noRefresh);
    const tokenSet = await this.getToken(refresh);
    if (tokenSet && tokenSet.access_token && tokenSet.access_token.length > 0) {
      options.headers = {
        Authorization: `Bearer ${tokenSet.access_token}`,
        ...options.headers,
      };
    }

    if ("body" in options && options.body instanceof Object) {
      options.body = JSON.stringify(options.body);
      options.headers = {
        ...options.headers,
        "Content-Type": "application/json",
      };
    }

    const features = this._localStorageService.get(StorageKey.FEATURES)
      ? JSON.parse(this._localStorageService.get(StorageKey.FEATURES) as string)
      : [];
    if (features && features.length > 0)
      options.headers = {
        ...options.headers,
        "Accept-Features": `{"features" ${features.join(" ")}}`,
      };

    let search = query ? `?${new URLSearchParams(query).toString()}` : "";
    if (absoluteQuery)
      search =
        search +
        (search && search.length > 0
          ? `&${absoluteQuery}`
          : `?${absoluteQuery}`);

    let nurl = this.baseUrl ? `${this.baseUrl}${url}` : `/-${url}`; // second part is for integration with react;
    if (absoluteUrl) {
      nurl = url;
    }
    const response = await fetch(`${nurl}${search}`, {
      method,
      credentials: "include",
      ...options,
    });
    if (response.ok) {
      const noContent = response.status === 201 || response.status === 204;
      return {
        headers: response.headers,
        body: noContent ? undefined : await response.json(),
      };
    } else if (response.status === 401 && refresh) {
      await this.refreshToken();
      // retry without refreshing the token
      return await this.request(
        method,
        url,
        query,
        absoluteUrl,
        options,
        false
      );
    } else {
      throw {
        response,
        body: await response.json(),
      };
    }
  }

  private async getToken(refresh = true): Promise<TokenSet | undefined> {
    const tokenSetString = this._localStorageService.get(
      "intervene-local-storage-tokenSet"
    );
    if (!tokenSetString || tokenSetString.length === 0) return undefined;
    const tokenSet = JSON.parse(tokenSetString) as TokenSet;
    if (refresh && tokenSet && tokenSet.expiresAt < Date.now() + 30 * 1000) {
      return await this.refreshToken();
    }
    return tokenSet;
  }

  private async refreshToken(): Promise<TokenSet | undefined> {
    const tokenSetString = this._localStorageService.get(StorageKey.TOKENSET);
    if (!tokenSetString || tokenSetString.length === 0) return undefined;
    const tokenSet = JSON.parse(tokenSetString) as TokenSet;
    const { body: newTokenSet } = await this.request(
      "POST",
      "/platform/users/refresh",
      null,
      false,
      {
        body: { refresh_token: tokenSet.refresh_token },
      },
      false
    );
    const tokensetToSet = {
      ...newTokenSet,
      expiresAt: Date.now() + tokenSet.expires_in * 1000 - 5 * 1000,
    };
    this._localStorageService.set(
      StorageKey.TOKENSET,
      JSON.stringify(tokensetToSet)
    );
    (window as any).REFRESH_TOKEN = null;
    return newTokenSet;
  }
}

export default ApiService;
