import { EmissionsRepresentationV2 } from "@/types/EmissionsRepresentationV2";
import EmissionTableRow from "@/types/EmissionTableRow";
import { v4 as uuidv4 } from "uuid";

class EmissionService {
  getTableRows(
    emissionsData: Array<EmissionsRepresentationV2>
  ): Array<EmissionTableRow> {
    const tableEmissions: Array<EmissionTableRow> = [];
    if (!emissionsData) return [];

    for (const emissionData of emissionsData) {
      const emissoinTable: EmissionTableRow = {
        class: "",
        totalCo2e: null,
        scope: null,
        tier: null,
        totalBreakDown: "",
        directCo2: "",
        fUnit: "",
        loc: "",
        children: [],
        key: uuidv4(),
      };
      const childGroups = emissionData.Breakdown
        ? this.getTableRows(emissionData.Breakdown.Indirect)
        : [];
      emissoinTable.class = emissionData.Class
        ? emissionData.Class[0].Name
        : null;

      emissoinTable.totalCo2e = emissionData.Emissions
        ? emissionData.Emissions.Co2e.ExpectedValue
        : 0;
      emissoinTable.fUnit =
        emissionData &&
        emissionData.FunctionalUnit &&
        emissionData.FunctionalUnit.length > 0
          ? emissionData.FunctionalUnit[0].Unit
          : "";
      emissoinTable.scope = emissionData.GhgpScope;
      emissoinTable.tier = emissionData.Tier;
      emissoinTable.loc = emissionData.Location
        ? emissionData.Location[0].Name
        : "";
      // totalBreakDown --> by hover we show a tooltip
      emissoinTable.children = childGroups;
      tableEmissions.push(emissoinTable);
    }
    return tableEmissions;
  }
}

export default new EmissionService();
