


















































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import ProductResponse from "@/types/ProductResponse";
import ProductService from "@/serives/ProductService";
import ExtractedProduct from "@/types/ExtractedProduct";
import {
  ProductInternalStatus,
  internalStatusMapper,
  externalStatusMapper,
  CGEngineStatusMapper,
  productDetailsMapper,
} from "@/types/ProductStatus";
import EmissionService from "@/serives/EmissionService";

export default defineComponent({
  name: "Product",
  methods: {
    forceUpdate() {
      this.$forceUpdate();
    },
  },
  setup(props, context) {
    const product = ref<ProductResponse>();
    const route = context.root.$route;
    const disableOriginalEdit = ref<boolean>(true);
    const disableCGEngineRequestEdit = ref<boolean>(true);
    const loadingExtraction = ref<boolean>(false);
    const loadingCGengine = ref<boolean>(false);
    const autoSizeProductDetials = ref<{ minRows: number; maxRows: number }>({
      minRows: 10,
      maxRows: 10,
    });
    const autoSizeCGEngineRequest = ref<{ minRows: number; maxRows: number }>({
      minRows: 10,
      maxRows: 10,
    });
    const emissions = ref<Array<any>>([]);
    const emissionColumns = [
      {
        title: "Class",
        dataIndex: "class",
        key: "class",
      },
      {
        title: "Total Co2e",
        dataIndex: "totalCo2e",
        key: "totalCo2e",
      },
      {
        title: "Scope",
        dataIndex: "scope",
        key: "scope",
      },
      {
        title: "Tier",
        dataIndex: "tier",
        key: "tier",
      },
      {
        title: "Total BreakDown",
        dataIndex: "totalBreakDown",
        key: "totalBreakDown",
      },
      {
        title: "Direct Co2",
        dataIndex: "directCo2",
        key: "directCo2",
      },
      {
        title: "F. Unit",
        dataIndex: "fUnit",
        key: "fUnit",
      },
      {
        title: "Loc.",
        dataIndex: "loc",
        key: "loc",
      },
    ];

    onMounted(async (): Promise<void> => {
      const productId = route.params.id;

      const { List } = await ProductService.getList(
        `filter=ID:${productId}`,
        0,
        1,
        undefined
      );
      product.value = List[0];

      if (product.value && !product.value.ExtractedProduct) {
        product.value.ExtractedProduct = {
          Title: undefined,
          Taxonomy: [],
          ProductDescription: [],
        } as ExtractedProduct;
      }

      if (
        product.value &&
        product.value.Emissions &&
        product.value.Emissions.Class
      )
        emissions.value = EmissionService.getTableRows([
          product.value.Emissions,
        ]);
    });

    const taxonomy = computed(() => {
      return product.value &&
        product.value.ExtractedProduct &&
        product.value.ExtractedProduct.Taxonomy
        ? product.value.ExtractedProduct.Taxonomy.join(",")
        : "";
    });

    const cgEngineStatus = computed(() => {
      if (!product.value) return undefined;
      return CGEngineStatusMapper(product.value.InternalStatus);
    });

    const productDetailsStatus = computed(() => {
      if (!product.value) return undefined;
      return productDetailsMapper(product.value.InternalStatus);
    });

    const onOriginalEdit = () => {
      disableOriginalEdit.value = false;
    };

    const onCGEngineRequestEdit = () => {
      disableCGEngineRequestEdit.value = false;
    };

    const onTitleChange = (event: any) => {
      if (product.value && product.value.ExtractedProduct) {
        product.value.ExtractedProduct.Title = event.target.value;
        save();
      }
    };

    const onTaxonomyChange = (event: any) => {
      const newTaxonomy = event.target.value;
      if (product.value && product.value.ExtractedProduct)
        product.value.ExtractedProduct.Taxonomy = newTaxonomy.split(",");
      save();
    };

    const onDescriptionChange = (event: any, index: number) => {
      if (product.value && product.value.ExtractedProduct && index > -1) {
        product.value.ExtractedProduct.ProductDescription[index].Html =
          event.target.value;
        const temp = product.value.ExtractedProduct.ProductDescription;
        product.value.ExtractedProduct.ProductDescription = [];
        product.value.ExtractedProduct.ProductDescription = temp;
      }
      save();
    };

    const save = async () => {
      if (product.value && product.value.ID) {
        await ProductService.updateDescription(
          product.value.ID,
          product.value.ExtractedProduct
            ? product.value.ExtractedProduct.Taxonomy
            : undefined,
          product.value.ExtractedProduct
            ? product.value.ExtractedProduct.Title
            : undefined,
          product.value.ExtractedProduct
            ? product.value.ExtractedProduct.ProductDescription
            : undefined
        );
        // TODO update the local storage as well
      }
    };

    const onUpdateStatus = () => {
      if (product.value && product.value.ID) {
        ProductService.updateStatus(
          product.value.ID,
          product.value.InternalStatus
        );
      }
    };

    const onReRunCGengine = async () => {
      if (product.value && product.value.ID) {
        loadingCGengine.value = true;
        await ProductService.ReRunEmissionsCalculation(product.value.ID);
        loadingCGengine.value = false;
      }
    };

    const onReRunExtraction = async () => {
      if (product.value && product.value.ID) {
        loadingExtraction.value = true;
        await ProductService.ReRunExtraction(product.value.ID);
        loadingExtraction.value = false;
      }
    };

    const onExpandProductDetails = () => {
      autoSizeProductDetials.value.maxRows = 100;
    };

    const onExpandCGEngineRequest = () => {
      autoSizeCGEngineRequest.value.maxRows = 100;
    };

    const handleStatusSelect = (data: any) => {
      if (product.value) product.value.InternalStatus = data.key;
    };

    const handleAddDescription = (): void => {
      if (
        product.value !== undefined &&
        product.value.ExtractedProduct !== undefined
      ) {
        if (
          product.value.ExtractedProduct.ProductDescription === null ||
          product.value.ExtractedProduct.ProductDescription === undefined
        )
          product.value.ExtractedProduct.ProductDescription = [];
        product.value.ExtractedProduct.ProductDescription.push({
          Html: "",
          Text: "",
        });
      }
    };

    return {
      product,
      disableOriginalEdit,
      disableCGEngineRequestEdit,
      taxonomy,
      autoSizeProductDetials,
      autoSizeCGEngineRequest,
      loadingExtraction,
      loadingCGengine,
      emissionColumns,
      emissions,
      ProductInternalStatus,
      cgEngineStatus,
      productDetailsStatus,
      // methods
      internalStatusMapper,
      externalStatusMapper,
      onOriginalEdit,
      onCGEngineRequestEdit,
      onTitleChange,
      onTaxonomyChange,
      onDescriptionChange,
      onUpdateStatus,
      onReRunCGengine,
      onReRunExtraction,
      onExpandProductDetails,
      onExpandCGEngineRequest,
      handleAddDescription,
      handleStatusSelect,
      CGEngineStatusMapper,
    };
  },
});
