
























































import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import ProductResponse from "@/types/ProductResponse";
import ProductToShow from "@/types/ProductToShow";
import ProductService from "@/serives/ProductService";
import {
  ProductInternalStatus,
  internalStatusMapper,
} from "@/types/ProductStatus";

export default defineComponent({
  name: "ProductList",
  setup(props, context) {
    const router = context.root.$router;
    const products = ref<Array<ProductResponse> | undefined>();
    const productsToShow = ref<Array<ProductToShow> | undefined>();
    const loading = ref<boolean>(false);
    const total = ref<number>(0);
    const remainingCount = ref<number>(0);
    const newlyAddedCount = ref<number>(0);
    const sortQuery = ref<string>("");
    const filterQuery = ref<string>("");
    const search = ref<string>("");
    const pagination = ref<{
      pageSize: number;
      current: number;
      total: number;
    }>({
      pageSize: 10,
      current: 0,
      total: 0,
    });

    const statusFilters: any = [];
    for (let status in ProductInternalStatus) {
      statusFilters.push({
        text: internalStatusMapper((ProductInternalStatus as any)[status]).text,
        value: (ProductInternalStatus as any)[status],
      });
    }

    const columns = computed(() => {
      return [
        {
          title: "Product ID",
          dataIndex: "ID",
          sorter: true,
          scopedSlots: { customRender: "productId" },
        },
        {
          title: "Product Title",
          dataIndex: "Title",
          sorter: true,
        },
        {
          title: "Quality",
          dataIndex: "quality",
          sorter: true,
          scopedSlots: { customRender: "quality" },
        },
        {
          title: "Status",
          dataIndex: "status",
          sorter: true,
          key: "status",
          filters: statusFilters,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Extraction time",
          dataIndex: "extractionTime",
          sorter: true,
        },
        {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
        },
      ];
    });

    onMounted(async (): Promise<void> => {
      const { TotalCount, List } = await ProductService.getList(
        undefined,
        pagination.value.current,
        pagination.value.pageSize,
        undefined
      );
      products.value = List;
      total.value = TotalCount;
      onNewList(TotalCount, List);
      await getRemainingItems();
      await getNewlyCreated();
    });

    const getNewlyCreated = async () => {
      const today = new Date();
      let lastweek = new Date();
      lastweek.setDate(today.getDate() - 7);
      const { TotalCount } = await ProductService.getList(
        `filter=Created>${lastweek.toISOString()}&filter=Created<${today.toISOString()}`,
        0,
        10,
        undefined
      );
      newlyAddedCount.value = TotalCount;
    };

    const getRemainingItems = async () => {
      const { TotalCount } = await ProductService.getList(
        `filter=Status:${ProductInternalStatus.PRODUCT_DESCRIPTION_EXTRACTION_FAILED},${ProductInternalStatus.PRODUCT_DESCRIPTION_CONFIDENCE_TOO_LOW}`,
        0,
        10,
        undefined
      );
      remainingCount.value = TotalCount;
    };

    const handleTableChange = async (
      newPagination: any,
      filters: any,
      sorter: any
    ) => {
      pagination.value.current = newPagination.current - 1;
      sortQuery.value = "";
      if (sorter && sorter.column) {
        let fieldName = "";
        let sign = "";
        if (sorter.column.dataIndex === "ID") {
          fieldName = "ID";
        } else if (sorter.column.dataIndex === "Title") {
          fieldName = "ExtractedProduct.Title";
        }
        sign = sorter.order === "ascend" ? "+" : "-";
        sortQuery.value = sign + fieldName;
      }
      filterQuery.value = "";
      let filterQueryString = "";
      if (filters) {
        for (let key in filters) {
          if (filters[key].length > 0) {
            filterQueryString =
              filterQueryString.length > 0
                ? filterQueryString + "&filter=" + key + ":"
                : filterQueryString + "filter=" + key + ":";
            let counter = 0;
            for (let filter in filters[key]) {
              counter++;
              filterQueryString =
                filterQueryString +
                (counter > 1
                  ? "," + filters[key][filter]
                  : filters[key][filter]);
            }
          }
        }
        filterQuery.value = filterQueryString;
      }
      const { List, TotalCount } = await ProductService.getList(
        checkSearch(),
        pagination.value.current,
        pagination.value.pageSize,
        sortQuery.value
      );
      onNewList(TotalCount, List);
    };

    const onEdit = async (product: ProductResponse) => {
      router.push({ path: `/product/${product.ID}` });
    };

    const onSearch = async (data: any) => {
      search.value = data;

      const { List, TotalCount } = await ProductService.getList(
        checkSearch(),
        pagination.value.current,
        pagination.value.pageSize,
        sortQuery.value
      );
      onNewList(TotalCount, List);
    };

    const onNewList = (TotalCount: number, List: Array<ProductResponse>) => {
      pagination.value.total = TotalCount;
      productsToShow.value = List.map((product) => {
        return {
          ID: product.ID,
          Title: product.ExtractedProduct
            ? product.ExtractedProduct.Title
            : product.Product
            ? product.Product.Title
            : "",
          Status: internalStatusMapper(product.InternalStatus),
          Quality: getQuality(product),
          ExatractionTime: product.ExtractionDuration,
        } as ProductToShow;
      });
    };

    const checkSearch = (): string => {
      const searchQuery =
        search && search.value && search.value.length > 0
          ? filterQuery.value +
            (filterQuery.value.length > 0
              ? "&filter=ExtractedProduct.Title~" + search.value
              : "filter=ExtractedProduct.Title~" + search.value)
          : filterQuery.value;
      return searchQuery;
    };

    const getQuality = (product: ProductResponse): string => {
      return product &&
        product.Emissions &&
        product.Emissions.Emissions &&
        product.Emissions.Emissions.Co2e.Distribution.Interval
        ? Math.round(
            (1 -
              product.Emissions.Emissions.Co2e.Distribution.Interval.Max -
              product.Emissions.Emissions.Co2e.Distribution.Interval.Min) *
              100
          ) + "%"
        : "-";
    };

    return {
      productsToShow,
      columns,
      loading,
      pagination,
      total,
      remainingCount,
      newlyAddedCount,
      handleTableChange,
      onEdit,
      onSearch,
    };
  },
});
